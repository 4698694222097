import "./Workflow.css";

import React, { useEffect } from "react"
import { Button, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Stack } from "react-bootstrap"
import { useMsal } from "@azure/msal-react";
import { callApiServer, downloadJobOutput } from "./utils/api";

export default function Workflows(props) {

  // State Initialization
  const [loading, setLoading] = React.useState(false);
  const [downloadDisabled, setDownloadDisabled] = React.useState(true);
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [executeDisabled, setExecuteDisabled] = React.useState(true);
  const [jobStatus, setJobStatus] = React.useState(null);
  const [jobStatusMsg, setJobStatusMsg] = React.useState("");
  const [ghStatusMsg, setGhStatusMsg] = React.useState("");
  const [workflowNameErrorMsg, setJobNameErrorMsg] = React.useState("");
  const [filledOut, setFilledOut] = React.useState(false);
  const [formData, setFormData] = React.useState({
    type: props.selectedResource.type,
    name: props.selectedResource.name,
    artifactFilename: "",
    ...(props.selectedResource.repo_path && {repo_path: props.selectedResource.repo_path}),
    ...(props.selectedResource.repo && {repo: props.selectedResource.repo}),
    ...(props.selectedResource.provider && {provider: props.selectedResource.provider}),
    parameters: props.selectedResource.parameters
      .reduce((acc, parameter) => {
        acc[parameter.input_name] = {
          value: null,
          required: parameter.required,
          type: parameter.datatype
        };
        return acc;
    }, {}),
  });
  const [jobID, setJobID] = React.useState(null);

  // Component Refs
  const footerRef = React.useRef(null);

  // MSAL Hooks
  const { accounts, instance } = useMsal();
 
  // Disable the execute button unless all required fields are filled out
  useEffect(() => {
    const _filledOut = Object.values(formData.parameters).every(parameter => {
      if (!parameter.required) {
        return true;
      }
      return parameter.value !== null && parameter.value !== '';
    })
    setFilledOut(_filledOut)
  }, [formData.parameters]);

  // Disable the download button unless a job is finished
  useEffect(() => {
    if (!filledOut || formData.artifactFilename === "" || workflowNameErrorMsg !== "") {
      setDownloadDisabled(true)
      setGhStatusMsg("")
      setLoading(false)
      setExecuteDisabled(true)
    } else if (['Requested', 'Queued', 'Running'].includes(jobStatus)) {
      setDownloadDisabled(true)
      setGhStatusMsg(jobStatus? `Your workflow is ${jobStatus.toLowerCase()}.` :"" )
      setLoading(jobStatus !== null)
      setExecuteDisabled(true)
    } else if (['Finished'].includes(jobStatus)) {
      setDownloadDisabled(false)
      setGhStatusMsg(jobStatusMsg !== "" ? jobStatusMsg : "Your job is finished.")
      setLoading(false)
      setExecuteDisabled(true)
    } else if (['Failed'].includes(jobStatus)) {
      setDownloadDisabled(true)
      setGhStatusMsg(jobStatusMsg !== "" ? jobStatusMsg : "Your job has failed. Double check your credentials and try again, or contact Lee Reese (Lee.Reese@NewEraTech.com) with your output files.")
      setLoading(false)
      setExecuteDisabled(false)
    } else {
      setExecuteDisabled(false)
      setGhStatusMsg("")
    }
  }, [jobStatus, workflowNameErrorMsg, formData.artifactFilename, filledOut])


  // Submit the job to the django-server
  const executeJob = async () => {
    // Convert formData to FormData object
    const formDataAsFormData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'parameters') {
        formDataAsFormData.append(key, JSON.stringify(value));
      } else {
        formDataAsFormData.append(key, value);
      }
    });

    // Hit django-server/execute-job/
    formDataAsFormData
    setJobStatus('Requested')
    callApiServer("/execute-job/", {
      method: 'POST',
      body: formDataAsFormData,
    })
    .then(response => response.json())
    .then(data => {
      setJobStatus(data.status)
    
      if (data.status === "Queued") {
        setJobID(data.UID);
        loopGetArtifact(4000, data.UID);
      } else {
        setJobStatus("Failed")
        console.log(data.message);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  // Poll the django-server for the job status
  const loopGetArtifact = (interval, UID) => {
    // Hit django-server/get-artifact/
    callApiServer(`/jobs/${UID}/status`, {'method': 'GET'})
    .then(response => response.json())
    .then(data =>{
      setJobStatus(data.status)
      // if there is a data.message, setJobStatusMsg to it
      if (data.message) {
        setJobStatusMsg(data.message)
      }
      if (["Running", "Queued"].includes(data.status)) { 
        setTimeout(loopGetArtifact.bind(null, interval, UID), interval);
      } else if (data.status === "Finished") {
        setDownloadUrl(data.downloadUrl);
      }
    }).catch(error => {
      console.error(error);
    });
  }

  /*
   *This function renders the input fields for a workflow parameter, will be called multiple times for
   *each workflow parameter.
   */
  const renderInputField = (parameter) => {
    const formControlType =
          parameter.datatype === 'string'  ? 'input'       : 
          parameter.datatype === 'secret'  ? 'password'    :
          parameter.datatype === 'file'  ? 'file'        :
          'input'   ;
    
    // Default Style
    const width = parameter.datatype === 'file' ? "20em" :"10em"
    let style = {
      width: width,
    }

    // If custom style is defined, union with the default style
    if (parameter.style) {
      const customStyle = styleStringToObject(parameter.style);
      style = {...style, ...customStyle}
    }

    return (
      <Row key={parameter.name} className="input-parameter">
        <Col>
          <FormControl
            style={{...style}}
            name={parameter.input_name}
            type={formControlType}
            placeholder={parameter.display_name}
            ref={parameter.ref}
            onChange={handleInputChange}
            autocomplete={parameter.datatype == 'secret' ? 'off': 'on'}
          />
          <div dangerouslySetInnerHTML={{ __html: parameter.description }} />
        </Col>
      </Row>
    );
  };

  const renderPermissions = (permissions) => {
    return (
      <>
        <h6>Required Permissions</h6>
        {permissions.map((permission, index) => (
          <p key={index}>
            {permission.text}
          </p>
        ))}
      </>
    )
  }

  const styleStringToObject = (styleString) => {
    return styleString.split(';')
      .filter(style => style.trim() !== '')
      .map(style => style.split(':'))
      .reduce((acc, [property, value]) => ({
        ...acc,
        [property.trim()]: value.trim(),
      }), {});
  }

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      const file = e.target.files[0];
      setFormData(prevState => ({
        ...prevState,
        [file.name]: file,
        parameters: {
          ...prevState.parameters,
          [name]: {
            ...prevState.parameters[name],
            value: file.name,
          }
        }
      }));
    }
    else {
      setFormData(prevState => ({
        ...prevState,
        parameters: {
          ...prevState.parameters,
          [name]: {
            ...prevState.parameters[name],
            value: value,
          }
        }
      }));
    }
  }

  const handleWorkflowNameChange = (e) => {
    const value = e.target.value;
    const disallowedChars = ['/', '\0', ' ', '*', '?', '[', ']', '!', '\'', '"', '$', '&', '(', ')', '|', ';', '<', '>', '#', '~', '{', '}', '%', '^'];
    const hasDisallowedChars = disallowedChars.some(char => value.includes(char));

    if (hasDisallowedChars) {
      setJobNameErrorMsg(`Filename cannot contain the following characters: ${disallowedChars.join(' ')}`);
    } else {
      setJobNameErrorMsg('');  // Clear the error message
      setFormData(prevState => ({
        ...prevState,
        artifactFilename: value
      }));
    }
  }



  return (
    <Container className="workflow-content">
      <Container className="input-parameters">
        <h3>Input Parameters</h3>
          {props.selectedResource.parameters.map(renderInputField)}
        <FormControl
          name={"artifactFilename"}
          type={"input"}
          style={{width: "10em"}}
          placeholder={"Workflow Title"}
          onChange={handleWorkflowNameChange}
        />
        <div>The title for your Workflow</div>
        <div className="error-message">{workflowNameErrorMsg}</div>
        <br />
        <div className = "button-group col-4">
          <Button style={{ boxShadow: "none"}}
                  onClick={executeJob}
                  variant="secondary"
                  disabled={false}>
            Execute Workflow
          </Button>
          <Button style={{marginLeft: "5px", boxShadow: "none"}}
                  variant="secondary"
                  onClick={() => {downloadJobOutput(jobID); setDownloadDisabled(true);}}
                  disabled={downloadDisabled}>
            <Spinner
              as="span"
              animation={loading? "border": false}
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Download Output
          </Button>
        </div>
        <div className="status-message">
          {ghStatusMsg}
        </div>
      </Container>

      
      <Container className="permissions">
        {props.selectedResource.permissions?.length > 0 ? (
          renderPermissions(props.selectedResource.permissions)
        ) : (
          <div />
        )}
      </Container>
    </Container>
  );
}
