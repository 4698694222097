import './NavBar.css';
import React, { useState, useEffect } from "react";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import SignOutButton from "./SignOutButton";
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

export default function Navbar(props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { instance, accounts } = useMsal();
  const [photoUrl, setPhotoUrl] = useState('');
  const [initials, setinitials] = useState('');

  useEffect(() => {
    const getProfilePhoto = async () => {
      if (accounts.length > 0) {
        const accessTokenResponse = await instance.acquireTokenSilent({
          scopes: ['user.read'],
          account: accounts[0],
        });

        const name = `${accounts[0].name}`
        const initials = name.split('').map((word) => word.charAt(0)).join('');
        setinitials(initials);

        if (accessTokenResponse) {
          const photoResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: {
              'Authorization': `Bearer ${accessTokenResponse.accessToken}`
            }
          });

          if (photoResponse.ok) {
            const photoBlob = await photoResponse.blob();
            const photoUrl = URL.createObjectURL(photoBlob);
            setPhotoUrl(photoUrl);
          }
        }
      }
    };

    getProfilePhoto();
  }, [instance, accounts]);

  const toggleNav = () => setIsNavExpanded(!isNavExpanded);

  return (
    <header className="Header">
      <div className="dropdown">
        <Link to="/">
          <img src='/owl_logo_no_bottom_text_white.svg' height='50px' style={{margin: 0, padding: 0}}/>
        </Link>
      </div>

      <nav className={isNavExpanded ? "d-flex active" : "d-flex"}>
        <AuthenticatedTemplate >
          <DropdownRight photoUrl={photoUrl} initials={initials} accounts={accounts} openJobs={props.openJobs} openRunbooks={props.openRunbooks} openFeedback={props.openFeedback} />
        </AuthenticatedTemplate >
      </nav>

      <div id="mobile" onClick={toggleNav}>
        <i id="bar" className={isNavExpanded ? "fa fa-times" : "fa fa-bars"} />
      </div>
    </header>
  )
}

const DropdownRight = ({ photoUrl, initials, accounts, openJobs, openRunbooks, openFeedback }) => (
  <div className="dropdown-right">
    <button className="avatar-button">
      <Avatar className="my-avatar"
        src={photoUrl ? photoUrl : initials ? null : ''}
        name={initials}
        size="40"
        round={true}
        color="white"
        fgColor="#202D69"
        textStyle={{ fontWeight: 'bold' }}
        onError={() => setPhotoUrl(null)}
      />
    </button>
    <div className="dropdown-content" style={{ right: "0" }}>
      <ProfileBox photoUrl={photoUrl} initials={initials} accounts={accounts} />
      <AuthenticatedTemplate >
        <DropdownLinks openJobs={openJobs} openFeedback={openFeedback} openRunbooks={openRunbooks} />
      </AuthenticatedTemplate>
    </div>
  </div>
);

const ProfileBox = ({ photoUrl, initials, accounts }) => (
  <div className="profile-box">
    <Avatar
      src={photoUrl ? photoUrl : initials ? null : ''}
      name={initials}
      size="40"
      round={true}
      onError={() => setPhotoUrl(null)}
    />
    <div className="profile-container">
      <p>{accounts.length > 0 ? `${accounts[0].name}` : " "}</p>
      <p>{accounts.length > 0 ? `${accounts[0].username}` : " "}</p>
    </div>
  </div>
);

const DropdownLinks = ({ openJobs, openRunbooks, openFeedback }) => (
  <>
    <p>
      <Link to="/jobs" style={{ all: 'unset' }}>
        <button className="navbar-dropdown-button" onClick={openJobs}>
          Workflow History
        </button>
      </Link>
    </p>
    <p>
      <Link to="/ " style={{ all: 'unset' }}>
        <button className="navbar-dropdown-button">
          Resource Catalog
        </button>
      </Link>
    </p>
    {import.meta.env.VITE_APP_RUNBOOKS_FEATURE_ENABLED === 'true' && (
    <p>
      <Link to="/runbooks" style={{ all: 'unset' }}>
        <button className="navbar-dropdown-button" onClick={openRunbooks}>
          My Runbooks
        </button>
      </Link>
    </p>
    )}
    <p>
      <Link to="/feedback " style={{ all: 'unset' }}>
        <button className="navbar-dropdown-button" onClick={openFeedback}>
          Feedback
        </button>
      </Link>
    </p>
    <p>
      <SignOutButton />
    </p>
  </>
);