import "../index.css"

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Container, Row } from "react-bootstrap"
import NavBar from "./NavBar";
import Footer from "./Footer";
import { loginRequest } from "../authConfig";

export const PageLayout = ({ children, ...props}) => {

    return (
        <Container className="flex-container">
            <NavBar className="header"/>
            <Container className="flex-content">
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect} 
                    authenticationRequest={loginRequest}
                >
                    {children}
                </MsalAuthenticationTemplate>
            </Container>
            <Footer className="footer"/>
        </Container>
    );
};