import React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-filter/lib/styles.css";
import "./index.css"
import { MsalProvider } from "@azure/msal-react";

import Jobs from "./Jobs";
import Feedback from "./Feedback";
import Resource from "./Resource";
import TestRunbook from "./TestRunbook";
import Runbook from "./Runbook";
import MyRunbooks from "./MyRunbooks";
import { PageLayout } from "./ui_components/PageLayout"
import { Home } from "./Home";
import { CustomNavigationClient } from "./ui_components/CustomNavigationClient";

import { BrowserRouter as Router, Routes, Route, useNavigate  } from 'react-router-dom';

function Main({ pca }) {
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/resource/:resourceId" element={<Resource />} />
          <Route exact path="/feedback" element={<Feedback />} />
          <Route exact path="/jobs" element={<Jobs />} />
          {import.meta.env.VITE_APP_RUNBOOKS_FEATURE_ENABLED === 'true' && (
            <>
              <Route exact path="/testrunbook" element={<TestRunbook />} />
              <Route exact path="/runbooks/:runbookId" element={<Runbook />} />
              <Route exact path="/runbooks" element={<MyRunbooks />} />
            </>
          )}
        </Routes>
      </PageLayout>
    </MsalProvider>
  );
}

export function App({ pca }) {
  return (
    <Router>
        <Main pca={pca} />
    </Router>
  );
}

