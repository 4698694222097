import './Catalog.css';

import { ResourceTable } from "./ui_components/ResourceTable";
import LoadingSpinner from "./ui_components/LoadingSpinner";
import { callApiServer } from './utils/api';
import { loginRequest } from './authConfig';

import React from "react"
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"


export default function Catalog({ height: number }) {

  // State Initialization
  const [resources, setResources] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [tableHeight, setTableHeight] = React.useState(100);
  const [loading, setLoading] = React.useState(true);
  const [resourceCatalogs, setResourceCatalogs] = React.useState(['All Resources']);
  const [catalogView, setCatalogView] = React.useState('All Resources')

  const filteredResources = React.useMemo(
    () => resources.filter(resource => {
      let matchesSearchFilter = true
      let matchesCatalogFilter = true
      if (searchTerm !== "") {
        matchesSearchFilter = resource.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resource.tags.some(r => r.toLowerCase().includes(searchTerm.toLowerCase())) ||
        resource.file_extension?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resource.catalogs.some(r => r.toLowerCase().includes(searchTerm.toLowerCase())) ||
        resource.synopsis.toLowerCase().includes(searchTerm.toLowerCase());
      } 
      if (catalogView !== "All Resources") {
        matchesCatalogFilter = resource.catalogs.includes(catalogView)
      } 
      return matchesSearchFilter && matchesCatalogFilter
    })
  , [resources, catalogView, searchTerm])

  // Load resources from the django server on page load.
  React.useEffect(() => {
    callApiServer('/docs/', {'method': 'GET'})
    .then(response => response.json())
    .then(data => {
      setResources(data);
      setResourceCatalogs(catalogs => [...catalogs, ...Array.from(new Set(data.flatMap(resource => resource.catalogs)))])
      setLoading(false);
    })
  }, []);
  

  return (
    <>
      <Container fluid className="catalog-header">
        <Row xs={3} className="search-and-catalog">
          <Col md={4} style={{display: "flex", alignItems: "center"}}>
            <div className="search-box">
              <button className="btn-search">
                <i className="fa fa-search"></i>
              </button>
              <DebouncedInput 
                value={searchTerm ?? ''}
                className="input-search" 
                required="required" 
                type="search" 
                onChange={value => setSearchTerm(value)}  
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearchTerm(e.currentTarget.value)
                  }
                }}
                placeholder={"e.g. title, tags, type"}
              />
            </div>
          </Col>
          <Col md={4} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Dropdown drop="down-centered" onSelect={setCatalogView} >
              <Dropdown.Toggle as="h2" style={{cursor: "pointer"}}>
                {catalogView}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {resourceCatalogs.map(catalog =>
                  <Dropdown.Item eventKey={catalog}>
                    {catalog}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={4}/>
        </Row>
        
      </Container>

      <div className="catalog-body">
        {loading ? ( 
          <LoadingSpinner />
        ) : (
          <ResourceTable className="resource-table"
            resources={filteredResources}
          />
        )}
      </div>
    </>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  placeholder,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input 
      {...props} 
      placeholder={isFocused ? placeholder : ""} 
      value={value} 
      onChange={e => setValue(e.target.value)} 
      onFocus={() => setIsFocused(true)} 
      onBlur={() => setIsFocused(false)}
    />
  )
}