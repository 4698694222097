import React from "react"
import { Card } from "react-bootstrap"
import SignInButton from "./ui_components/SignInButton";

function LandingPage() {
  return (
    <div>
      <Card.Title style = {{paddingTop: '75px', display: 'flex', justifyContent: 'center', paddingBottom: '0.5em'}}>
        You must sign in to continue.
      </Card.Title>

      <div style = {{justifyContent: 'center', alignItems : 'center', display: 'flex'}}>
        <SignInButton />
      </div>

      <div style = {{paddingTop: '20px', display: 'flex', justifyContent: 'center'}}>
        <img src={'/owl_logo_transparent.png'} alt="logo" />
      </div>
    </div>
  );
}

export default LandingPage;
