import React from "react";
import { useMsal } from "@azure/msal-react";
import { BiLogOut } from "react-icons/bi";



export default function SignOutButton() {
  const { instance } = useMsal();

  const handleLogout = () => instance.logoutRedirect();

  return (
  
      <button className="navbar-dropdown-button" onClick={handleLogout}>
        <BiLogOut size={28} /> Sign Out
      </button>
   
  );
}
