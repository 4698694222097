import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="copyright">
                <p>&copy; 2024 New Era Technology. All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default Footer;
