import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

import { Button } from "react-bootstrap"


export default function SignInButton() {
  const { instance } = useMsal();

  const handleLogin = (event) => {
    event.preventDefault()
    instance.loginRedirect(loginRequest).catch(e => {
      console.error("ERROR LOGGING IN",e);
    });
  }

  return (
    <div>
      <Button onClick={handleLogin}>
        Sign In
      </Button>
    </div>
  );
}
