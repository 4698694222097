import "./Jobs.css"

import * as React from "react";
import { Button } from "react-bootstrap";

import { JobTable } from "./ui_components/JobTable";
import { Container, Spinner } from "react-bootstrap"
import { callApiServer } from "./utils/api";
import LoadingSpinner from "./ui_components/LoadingSpinner";


export default function Jobs(props) {
    const [jobs, setJobs] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [tableLoading, setTableLoading] = React.useState(true);
    const [refreshLoading, setRefreshLoading] = React.useState(false);
    const [statusMsg, setStatusMsg] = React.useState('');
    const [selectedJobIds, setSelectedJobIds] = React.useState([]);
    const [deleteButtonEnabled, setDeleteButtonEnabled] = React.useState(false);
    const [cancelButtonEnabled, setCancelButtonEnabled] = React.useState(false);

    const updateTable = () => {
      setRefreshLoading(true);
      callApiServer("/jobs/", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
      })
      .then(response => response.json())
      .then(data => {
        setJobs(data.jobs);
        setRefreshLoading(false);
        if (firstLoad) {
          setTableLoading(false);
          setFirstLoad(false);
        }
      })
    }

    // Initial Page load updates table
    React.useEffect(() => {
        updateTable();
    }, [])

    // Subsequent refreshes to update table
    React.useEffect(() => {
      // Starts the interval when the page becomes visible
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          // Start the interval when the page is visible
          intervalId = setInterval(() => {
            updateTable();
          }, 20000);
        } else {
          // Clear the interval when the page is hidden
          clearInterval(intervalId);
        }
      };
    
      let intervalId;
      document.addEventListener('visibilitychange', handleVisibilityChange);
      handleVisibilityChange();
    
      return () => {
        // Clean up the interval and event listener on component unmount
        clearInterval(intervalId);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);

    // Update the delete and cancel buttons states
    React.useEffect(() => {
      const _deleteButtonEnabled = selectedJobIds.length > 0
      const _cancelButtonEnabled = selectedJobIds.length > 0 && selectedJobIds.every(jobId => jobs.find(job => job.id === jobId).status === "Running");
      setDeleteButtonEnabled(_deleteButtonEnabled);
      setCancelButtonEnabled(_cancelButtonEnabled);
      console.log("Rows Selected: ", selectedJobIds);
    }, [selectedJobIds])

    const cancelSelectedJobs = () => {
      setCancelButtonEnabled(false);
      document.body.style.cursor = 'progress';
      let cancelledJobs = [];
      let cancelPromises = selectedJobIds.map((jobId) => {
        return callApiServer(`/jobs/${jobId}/cancel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(data => {
          cancelledJobs.push(data.job_name);
        })
        .catch(error => {
          console.error("Error deleting job: ", error);
        });
      });

      Promise.all(cancelPromises).then(() => {
        updateTable();
        setStatusMsg(`Cancelled jobs: ${cancelledJobs.join(", ")}`);
        document.body.style.cursor = 'default';
      });
    }

    const deleteSelectedJobs = () => {
      setDeleteButtonEnabled(false);
      document.body.style.cursor = 'progress';
      let deletedJobs = [];
      let deletePromises = selectedJobIds.map((jobId) => {
        return callApiServer(`/jobs/${jobId}/delete`, {
          method: 'POST',
          headers: {
        'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(data => {
          deletedJobs.push(data.job_name);
        })
        .catch(error => {
          console.error("Error deleting job: ", error);
        });
      });

      Promise.all(deletePromises).then(() => {
        updateTable();
        setStatusMsg(`Deleted jobs: ${deletedJobs.join(", ")}`);
        document.body.style.cursor = 'default';
      });
    }
 
    // Refresh button styles, make transparent if refreshLoading is not true
    const refreshSpinnerColorStyle = refreshLoading ? { color: "grey" } : { color: "transparent" };
    const refreshSpinnerStaticStyle = { height: "20px", width: "20px", alignSelf: "flex-end" };
    const refreshSpinnerStyle = { ...refreshSpinnerStaticStyle, ...refreshSpinnerColorStyle };

    return (
      <Container style={{display: 'flex', flexDirection: 'column', alignContent: 'flex-start'}}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h2>Workflow History</h2>
      </div>
      <div className="workflow-history-content" style={{ margin: "0.5em", overflow: "hidden" }}>
        {tableLoading ? (
        <LoadingSpinner />
        ) : (
        <>
          <JobTable jobs={jobs} setStatusMsg={setStatusMsg} setRowsSelected={setSelectedJobIds}/>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Note: You can only view the past seven (7) days jobs.</p>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="refresh-spinner"
              style={refreshSpinnerStyle}
            />
          </div>
          <div className='button-row-with-status-msg'>
            <Container style={{ display: 'flex', justifyContent: 'flex-start', marginTop: "1em", flexDirection: 'row' }}>
              <Button
                className="padded-button"
                disabled={!cancelButtonEnabled}
                onClick={cancelSelectedJobs}
              >
                Cancel
              </Button>
              <Button
                className="padded-button"
                disabled={!deleteButtonEnabled}
                onClick={deleteSelectedJobs}
              >
                Delete
              </Button>
            </Container>
            <p style={{paddingTop: "1em"}}>{statusMsg}</p>
          </div>
        </>
        )}
      </div>
      </Container>
    )
}