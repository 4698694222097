

export default function LoadingSpinner(): JSX.Element {
    return (
        <div className="spinner">
            <div className="spinner-logo">
                <img src="/hybrid_pathways_links.png" alt="hybrid pathways logo" style={{ height: 45, padding: "2px" }} />
            </div>
        </div>
    )
}