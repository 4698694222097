import "./Feedback.css"

import * as React from "react";

import { Row, Col, Container} from "react-bootstrap"
import { useMsal } from "@azure/msal-react";
import { propTypes } from "react-bootstrap/esm/Image";
import { callApiServer } from "./utils/api";


const FeedbackForm = () => {
    const [feedback, setFeedback] = React.useState('');

    // MSAL Hooks
    const { accounts, instance } = useMsal();

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        callApiServer("/feedback/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ feedback: feedback })
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error))
        .finally(() => setFeedback(''));
    }

    return (
    <>
        <h2 className="title">Feedback</h2>
        <form onSubmit={handleSubmit}>
            <Container className="feedback-form">
                <textarea
                    id="feedback"
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                    className = "feedback-textarea"
                />
                <button className="submit-button" type="submit">Submit</button>
            </Container>
        </form>
    </>
    );
};

export default FeedbackForm;