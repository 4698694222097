import "./DocumentEmbed.css"

import * as React from 'react'
import { Button, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Stack } from "react-bootstrap"

import { callMsGraph } from "../utils/MsGraphApiCall";
import { callApiServer, downloadResource } from '../utils/api';
import LoadingSpinner from "./LoadingSpinner";

export default function DocumentEmbed(props): JSX.Element {
    const { owlId }: { owlId: string } = props;

    // State Initialization
    const [previewLink, setPreviewLink] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        callApiServer(`/resources/${owlId}/preview`, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
            setPreviewLink(data.previewUrl)
        })
    }, [owlId]);

    return (
        <>
            <div className="document-preview-iframe">
                {isLoading && (
                    <LoadingSpinner />
                )}
                <iframe
                    src={previewLink}
                    width="100%"
                    height="100%"
                    style={{ paddingRight: 0 }} 
                    onLoad={() => setIsLoading(false)
                    }
                />
            </div>
            <div className="document-preview-footer" style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="col-4" style={{textAlign: 'left'}} />
    
                <div className="col-4" style={{textAlign: 'center'}} />
    
                <div className="col-4" style={{textAlign: 'right'}}>
                    <Button variant="secondary" onClick={() => downloadResource(owlId, "document")} style={{marginRight: "0.5em", boxShadow: "none"}}>
                        Download
                    </Button>
                </div>
            </div>
        </>
    )

}