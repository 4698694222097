import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LandingPage from "./LandingPage";
import Catalog from "./Catalog";

export function Home() {
  return (
      <>
          <AuthenticatedTemplate>
            <Catalog />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LandingPage />
          </UnauthenticatedTemplate>
      </>
  );
}